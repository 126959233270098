<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="reporter-list-view" class="data-list-container">
    <feather-icon class="sm:inline-flex xl:hidden cursor-pointer" icon="MenuIcon" style="height: 20px; margin-right: 10px;" @click.stop="showSidebar"></feather-icon>
    <vs-table ref="table" search :data="users">
      <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
        <div class="vs-table--search" style="margin: 5px 15px 5px 0px">
          <vs-button
            color="primary"
            type="border"
            class="p-2 cursor-pointer"
            @click="createUserModalActive = true"
          >추가</vs-button>
        </div>

        <!-- ITEMS PER PAGE -->
        <vs-dropdown vs-trigger-click class="cursor-pointer mr-4 mb-4">
          <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
          <vs-dropdown-menu>
            <vs-dropdown-item @click="changeItemPerPage(4)">
              <span>4</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="changeItemPerPage(10)">
              <span>10</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="changeItemPerPage(15)">
              <span>15</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="changeItemPerPage(20)">
              <span>20</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>

      <template slot="thead">
        <vs-th sort-key="name">이름</vs-th>
        <vs-th sort-key="account_id">아이디</vs-th>
        <vs-th sort-key="hp">전화</vs-th>
        <vs-th>권한</vs-th>
      </template>

      <template slot-scope="{data}">
        <tbody>
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td><div v-on:click="showUserInfo(tr.account_id)" style="font-weight: bold">{{ tr.name }}</div></vs-td>
            <vs-td><div>{{ tr.account_id }}</div></vs-td>
            <vs-td><div>{{ tr.hp }}</div></vs-td>
            <vs-td v-if="tr.granted == 'yes'"><div><font color="blue"><b>승인</b></font></div></vs-td>
            <vs-td v-if="tr.granted != 'yes'"><div><font color="red"><b>거부</b></font></div></vs-td>
          </vs-tr>
        </tbody>
      </template>
    </vs-table>

    <!-- <div>
      <vs-pagination :total="numOfPages" v-model="currentx"></vs-pagination>
    </div> -->

    <!-- Start: 계정 생성 팝업 -->
    <div class="create-user-form">
      <vs-popup classContent="popup-example" title="계정 생성" :active.sync="createUserModalActive">
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>이름</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full" style="height: 48px;">
            <vs-input :success="regExpName.test(userModalData.createUserQueryData.name)" 
                      :danger="!regExpName.test(userModalData.createUserQueryData.name)"
                      danger-text="완전한한글, 영문, 숫자, _로 2~20글자 입력해야 하며, 특수문자를 입력할 수 없습니다."
                      class="w-full" v-model="userModalData.createUserQueryData.name"/>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>아이디</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full" style="height: 48px;">
            <vs-input :success="regExpAccountId.test(userModalData.createUserQueryData.account_id)"
                      :danger="!regExpAccountId.test(userModalData.createUserQueryData.account_id)"
                      danger-text="아이디는 6~12글자, 공백 및 특수문자를 포함할 수 없습니다."
                      class="w-full" v-model="userModalData.createUserQueryData.account_id"/>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>비밀번호</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full" style="height: 48px;">
            <vs-input :success="regExpPassword.test(userModalData.createUserQueryData.password)"
                      :danger="!regExpPassword.test(userModalData.createUserQueryData.password)"
                      danger-text="비밀번호는 8~15개의 영문 대소문자, 1개 이상의 특수문자를 반드시 포함해야 합니다."
                      class="w-full" type="password" v-model="userModalData.createUserQueryData.password"/>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>전화</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full" style="height: 48px;">
            <vs-input :success="regExpHp.test(userModalData.createUserQueryData.hp)"
                      :danger="!regExpHp.test(userModalData.createUserQueryData.hp)"
                      danger-text="올바른 형식의 전화번호를 입력해주세요."
                      class="w-full" v-model="userModalData.createUserQueryData.hp"/>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>권한</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <vs-switch style="width: 64px" color="success" v-model="userModalData.createUserQueryData.granted">
              <span slot="on">승인</span>
              <span slot="off">거부</span>
            </vs-switch>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col sm:w-2/3 w-full ml-auto" style="margin-bottom: 60px;">
            <vs-button class="mr-3 mb-2" @click="createUser()">생성</vs-button>
            <vs-button
              color="warning"
              type="border"
              class="mb-2"
              @click="createUserModalInfoResetModalActive=true"
            >초기화</vs-button>
          </div>
          <div class="demo-alignment">
            <vs-popup classContent="popup-example" title="주의!!" :active.sync="createUserModalInfoResetModalActive">
              <p style="padding: 20px">작성한 정보가 모두 초기화됩니다. 계속 진행하시겠습니까?</p>

              <div style="padding:20px; display: inline-block;">
              <vs-button @click="resetQueryDataOfUserCreateModal(); createUserModalInfoResetModalActive=false" color="primary" type="filled">예</vs-button>
              </div>
              <div style="margin-bottom: 20px; display: inline-block;">
              <vs-button @click="createUserModalInfoResetModalActive=false" color="primary" type="filled">아니오</vs-button>
              </div>

            </vs-popup>
          </div>
        </div>
      </vs-popup>
    </div>
    <!-- End: 계정 생성 팝업 -->

    <!-- Start: 계정 수정 팝업 -->
    <vs-popup classContent="popup-example" title="계정 수정" :active.sync="editUserModalActive">
      <div class="edit-user-form">
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>이름</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full" style="height: 48px;">
            <vs-input :success="regExpName.test(userModalData.editUserQueryData.name)" 
                      :danger="!regExpName.test(userModalData.editUserQueryData.name)"
                      danger-text="완전한한글, 영문, 숫자, _로 2~20글자 입력해야 하며, 특수문자를 입력할 수 없습니다."
                      class="w-full" v-model="userModalData.editUserQueryData.name"/>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>아이디</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full" style="height: 48px;">
            <vs-input :success="regExpAccountId.test(userModalData.editUserQueryData.account_id)"
                      :danger="!regExpAccountId.test(userModalData.editUserQueryData.account_id)"
                      danger-text="아이디는 6~12글자, 공백 및 특수문자를 포함할 수 없습니다."
                      class="w-full" v-model="userModalData.editUserQueryData.account_id"/>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>비밀번호</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full" style="height: 48px;">
            <vs-input :success="regExpPassword.test(userModalData.editUserQueryData.password)"
                      :warning="!regExpPassword.test(userModalData.editUserQueryData.password)"
                      warning-text="비밀번호는 8~15개의 영문 대소문자, 1개 이상의 특수문자를 반드시 포함해야 합니다."
                      class="w-full" type="password" v-model="userModalData.editUserQueryData.password"/>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>전화</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full" style="height: 48px;">
            <vs-input :success="regExpHp.test(userModalData.editUserQueryData.hp)"
                      :danger="!regExpHp.test(userModalData.editUserQueryData.hp)"
                      danger-text="올바른 형식의 전화번호를 입력해주세요."
                      class="w-full" v-model="userModalData.editUserQueryData.hp"/>
          </div>
        </div>
        
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>권한</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <vs-switch style="width: 64px" color="success" v-model="userModalData.editUserQueryData.granted">
              <span slot="on">승인</span>
              <span slot="off">거부</span>
            </vs-switch>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col sm:w-2/3 w-full ml-auto" style="margin-bottom: 60px;">
            <vs-button class="mr-3 mb-2" @click="editUser()">수정</vs-button>
            <vs-button
              color="warning"
              type="border"
              class="mb-2"
              @click="editUserModalInfoCancelModalActive=true"
            >취소</vs-button>
          </div>
          <div class="demo-alignment2">
            <vs-popup classContent="popup-example2" title="수정 취소" :active.sync="editUserModalInfoCancelModalActive">
              <p style="padding: 20px">취소하시겠습니까?</p>
              <div style="padding:20px; display: inline-block;">
              <vs-button @click="resetQueryDataOfUserEditModal(); editUserModalInfoCancelModalActive=false; editUserModalActive=false;" color="primary" type="filled">예</vs-button>
              </div>
              <div style="margin-bottom: 20px; display: inline-block;">
              <vs-button @click="editUserModalInfoCancelModalActive=false" color="primary" type="filled">아니오</vs-button>
              </div>
            </vs-popup>
          </div>
        </div>
      </div>
    </vs-popup>
    <!-- End: 계정 수정 팝업 -->

  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      regExpName: /^[가-힣a-zA-Z0-9_ ]{2,20}$/,
      regExpAccountId: /^[a-zA-Z0-9]{6,12}$/,
      regExpPassword: /^(?=.*[a-zA-Z])((?=.*\d)|(?=.*\W)).{8,15}$/,
      regExpEmail: /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i,
      regExpHp: /^\d{3}-\d{4}-\d{4}$/,
      createUserModalActive: false,
      editUserModalActive: false,
      createUserModalInfoResetModalActive: false,
      editUserModalInfoCancelModalActive: false,
      users: [],
      currentx: 1,
      numOfUsers: 0,
      numOfPages: 0,
      searchText: "",
      userModalData: {
        createUserQueryData: {
          name: '',
          account_id: '',
          password: '',
          hp: '',
          granted: false,
        },
        editUserQueryData: {
          name: '',
          target_account_id: '',
          account_id: '',
          password: '',
          hp: '',
          granted: false
        },
        editUserQueryDataOrigin: {
          name: '',
          account_id: '',
          password: '',
          hp: '',
          granted: false
        },
        optionData: {
          grantOption: [
            { text: "승인", value: "1" },
            { text: "거부", value: "0" }
          ]
        },
      },
      renderMethod: {
        startRow: 0,
        itemsPerPage: 10,
        factor: "name",
        inputText: ""
      },
      isMounted: false
    };
  },
  beforeRouteLeave (to, from, next) {
      this.createUserModalActive = false;
      this.editUserModalActive = false;
      this.createUserModalInfoResetModalActive = false;
      this.editUserModalInfoCancelModalActive = false;
      next();
  },
  computed: {
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx;
      }
      return 0;
    }
  },
  methods: {
    changeItemPerPage(pageNum) {
      this.renderMethod.itemsPerPage = pageNum;
      this.getUserList();
    },

    factorToText(menu) {
      let menuText;
      switch (menu) {
        case "name":
          menuText = "이름";
          break;
        case "account_id":
          menuText = "아이디";
          break;
        case "hp":
          menuText = "전화";
          break;
      }
      return menuText;
    },

    isExistUser(account_id, func) {

      var thisObj = this
      
      thisObj.$http
        .post("/api/adComp/exists", {account_id: account_id})
        .then(function(response) {
          
          console.log("exists)", response.data.exists)
          if(response.data.exists == "yes") {
            
            func(true)
          }
          else if(response.data.exists == "no") {

            func(false)
          }
          else {

            thisObj.$vs.notify({
              title:'계정 생성 실패',
              text:"알 수 없는 오류로 인해 계정 생성에 실패했습니다.",
              color:'danger'})

            console.log("invalid data: "+response.data.exist)
          }
        })
        .catch(function(error) {
          var errorText = error.toString();
          if(errorText.includes('401')){
            alert('로그인 해주세요!')
            thisObj.$router.push({path: '/pages/login?to='+ thisObj.$router.history.current.path, query: thisObj.$router.history.current.query})
          }
          thisObj.$vs.notify({
            title: "Error",
            text: error,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle"
          });
        });
    },

    mapUserInfoAtEditModal(userInfo) {

      this.userModalData.editUserQueryData.name = userInfo.name
      this.userModalData.editUserQueryData.account_id = userInfo.account_id
      this.userModalData.editUserQueryData.password = ''
      this.userModalData.editUserQueryData.hp = userInfo.hp
      this.userModalData.editUserQueryData.granted = userInfo.granted == "yes"
      
      this.userModalData.editUserQueryDataOrigin.name = userInfo.name
      this.userModalData.editUserQueryDataOrigin.account_id = userInfo.account_id
      this.userModalData.editUserQueryDataOrigin.password = ''
      this.userModalData.editUserQueryDataOrigin.hp = userInfo.hp
      this.userModalData.editUserQueryDataOrigin.granted = userInfo.granted == "yes"
    },

    showUserInfo(account_id) {

      var thisObj = this;

      thisObj.$http
          .post("/api/adComp/info", {account_id: account_id})
          .then(function(response) {

            console.log(response.data)
            thisObj.mapUserInfoAtEditModal(response.data)
            thisObj.editUserModalActive = true
          })
          .catch(function(error) {
            var errorText = error.toString();
            if(errorText.includes('401')){
              alert('로그인 해주세요!')
              console.log(thisObj.$router)
              thisObj.$router.push({path: '/pages/login?to='+ thisObj.$router.history.current.path, query: thisObj.$router.history.current.query})
            }
            thisObj.$vs.notify({
              title: "Error",
              text: error,
              color: "danger",
              iconPack: "feather",
              icon: "icon-alert-circle"
            });
          });
    },

    editUser() {

      var thisObj = this

      if(!thisObj.isPassedIntegrityVerification("edit_user")) {
        return;
      }

      // if account id edited.
      if(thisObj.userModalData.editUserQueryDataOrigin.account_id != thisObj.userModalData.editUserQueryData.account_id) {

        thisObj.isExistUser(thisObj.userModalData.editUserQueryData.account_id, function(exist) {

          if(exist) {
            
            thisObj.$vs.notify({
              title:'중복 아이디',
              text:"중복된 아이디입니다.",
              color:'warning'})

            return
          }
        })
      }
      
      thisObj.runEditUser()
    },

    createUser() {

      var thisObj = this

      if(!thisObj.isPassedIntegrityVerification("create_user")) {
        return;
      }

      thisObj.isExistUser(thisObj.userModalData.createUserQueryData.account_id, function(exist) {
        
        if(exist) {
          
          thisObj.$vs.notify({
            title:'중복 아이디',
            text:"중복된 아이디입니다.",
            color:'warning'})

          return;
        }

        thisObj.createUserModalActive = false

        thisObj.$http
          .post("/api/adComp/register", thisObj.userModalData.createUserQueryData)
          .then(function(response) {
            
            if(response.data.status === "success") {

              thisObj.users = response.data.users

              thisObj.$vs.notify({
                title:'계정 생성 완료',
                text:"계정 '"+thisObj.userModalData.createUserQueryData.name+"' 생성 완료.",
                color:'success'})
              
              thisObj.resetQueryDataOfUserCreateModal()
              thisObj.getUserList()
            }
            else {

              console.log('response) ', response)

              thisObj.$vs.notify({
                title:'계정 생성 실패',
                text:"계정 '"+thisObj.userModalData.createUserQueryData.name+"' 생성 실패.",
                color:'danger'})
            }
          })
          .catch(function(error) {
            var errorText = error.toString();
            if(errorText.includes('401')){
              alert('로그인 해주세요!')
              console.log(thisObj.$router)
              thisObj.$router.push({path: '/pages/login?to='+ thisObj.$router.history.current.path, query: thisObj.$router.history.current.query})
            }
            thisObj.$vs.notify({
              title: "Error",
              text: error,
              color: "danger",
              iconPack: "feather",
              icon: "icon-alert-circle"
            });
          });
      })
    },

    runEditUser() {

      var thisObj = this

      thisObj.editUserModalActive = false
      
      thisObj.userModalData.editUserQueryData.target_account_id = thisObj.userModalData.editUserQueryDataOrigin.account_id

      thisObj.$http
          .post("/api/adComp/edit", thisObj.userModalData.editUserQueryData)
          .then(function(response) {
            
            if(response.data.status === "success") {

              thisObj.$vs.notify({
                title:'계정 수정 완료',
                text:"계정 '"+thisObj.userModalData.editUserQueryDataOrigin.account_id+"' 수정 완료.",
                color:'success'})
              
              thisObj.users = response.data.users
              thisObj.resetQueryDataOfUserEditModal()
              thisObj.getUserList()
            }
            else {
              thisObj.$vs.notify({
                title:'계정 수정 실패',
                text:"계정 '"+thisObj.userModalData.editUserQueryDataOrigin.account_id+"' 수정 실패.",
                color:'danger'})
            }
          })
          .catch(function(error) {
              var errorText = error.toString();
              if(errorText.includes('401')){
                alert('로그인 해주세요!')
                console.log(thisObj.$router)
                thisObj.$router.push({path: '/pages/login?to='+ thisObj.$router.history.current.path, query: thisObj.$router.history.current.query})
              }
              thisObj.$vs.notify({
                title: "Error",
                text: error,
                color: "danger",
                iconPack: "feather",
                icon: "icon-alert-circle"
              });
          });
    },

    resetQueryDataOfUserCreateModal() {
      this.userModalData.createUserQueryData.name
      = this.userModalData.createUserQueryData.account_id
      = this.userModalData.createUserQueryData.password
      = this.userModalData.createUserQueryData.hp
      = this.userModalData.createUserQueryData.granted
      = ''
    },
    resetQueryDataOfUserEditModal() {
      this.userModalData.editUserQueryData.name
      = this.userModalData.editUserQueryData.account_id
      = this.userModalData.editUserQueryData.password
      = this.userModalData.editUserQueryData.hp
      = this.userModalData.editUserQueryData.granted
      = ''
    },
    isPassedIntegrityVerification(type) {

      if(type === "create_user") {

        var fillDataCheck = (this.userModalData.createUserQueryData.name && this.userModalData.createUserQueryData.name != '')
                && (this.userModalData.createUserQueryData.account_id && this.userModalData.createUserQueryData.account_id != '')
                && (this.userModalData.createUserQueryData.password && this.userModalData.createUserQueryData.password != '')
                && (this.userModalData.createUserQueryData.hp && this.userModalData.createUserQueryData.hp != '')

        var regExpName = /^[가-힣a-zA-Z0-9_ ]{2,20}$/
        var nameCheck = regExpName.test(this.userModalData.createUserQueryData.name)

        var regExpAccountId = /^[a-zA-Z0-9]{6,12}$/
        var accountIdCheck = regExpAccountId.test(this.userModalData.createUserQueryData.account_id)

        var regExpPassword = /^(?=.*[a-zA-Z])((?=.*\d)|(?=.*\W)).{6,20}$/
        var passwordCheck = regExpPassword.test(this.userModalData.createUserQueryData.password)

        var regExpHp = /^\d{3}-\d{4}-\d{4}$/
        var hpCheck = regExpHp.test(this.userModalData.createUserQueryData.hp)
        
        if(!fillDataCheck) {

          this.$vs.notify({
            title:'미입력',
            text:"빠짐없이 입력 하세요.",
            color:'warning'})

            return false;
        }
        
        if(!nameCheck) {

          this.$vs.notify({
            title:'이름',
            text:"완전한한글, 영문, 숫자, _로 2~20글자 입력해야 하며, 특수문자를 입력할 수 없습니다.",
            color:'warning'})
        }
        
        if(!accountIdCheck) {

          this.$vs.notify({
            title:'아이디',
            text:"아이디는 6~12글자, 공백 및 특수문자를 포함할 수 없습니다.",
            color:'warning'})
        }
        if(!passwordCheck) {

          this.$vs.notify({
            title:'비밀번호',
            text:"비밀번호는 8~15개의 영문 대소문자, 1개 이상의 특수문자를 반드시 포함해야 합니다.",
            color:'warning'})
        }
        if(!hpCheck) {

          this.$vs.notify({
            title:'전화번호',
            text:"올바른 형식의 전화번호를 입력해주세요.",
            color:'warning'})
        }

        return nameCheck && accountIdCheck && passwordCheck && hpCheck
      }
      else if(type === "edit_user") {

        var nameChanged = this.userModalData.editUserQueryData.name !== this.userModalData.editUserQueryDataOrigin.name
        var accountIdChanged = this.userModalData.editUserQueryData.account_id !== this.userModalData.editUserQueryDataOrigin.account_id
        var passwordChanged = this.userModalData.editUserQueryData.password !== this.userModalData.editUserQueryDataOrigin.password
        var hpChanged = this.userModalData.editUserQueryData.hp !== this.userModalData.editUserQueryDataOrigin.$http

        fillDataCheck = (nameChanged ? (this.userModalData.editUserQueryData.name && this.userModalData.editUserQueryDataOrigin.name != '') : true)
                && (accountIdChanged ? (this.userModalData.editUserQueryData.account_id && this.userModalData.editUserQueryDataOrigin.account_id != '') : true)
                && (hpChanged ? (this.userModalData.editUserQueryData.hp && this.userModalData.editUserQueryDataOrigin.hp != '') : true)

        regExpName = /^[가-힣a-zA-Z0-9_ ]{2,20}$/
        nameCheck = nameChanged ? regExpName.test(this.userModalData.editUserQueryData.name): true 

        regExpAccountId = /^[a-zA-Z0-9]{6,12}$/
        accountIdCheck = accountIdChanged ? regExpAccountId.test(this.userModalData.editUserQueryData.account_id) : true

        regExpPassword = /^(?=.*[a-zA-Z])((?=.*\d)|(?=.*\W)).{6,20}$/
        passwordCheck = passwordChanged ? regExpPassword.test(this.userModalData.editUserQueryData.password) : true

        regExpHp = /^\d{3}-\d{4}-\d{4}$/
        hpCheck = hpChanged ? regExpHp.test(this.userModalData.editUserQueryData.hp) : true
        
        if(!fillDataCheck) {

          this.$vs.notify({
            title:'미입력',
            text:"빠짐없이 입력 하세요.",
            color:'warning'})

            return false;
        }
        
        if(!nameCheck) {

          this.$vs.notify({
            title:'이름',
            text:"완전한한글, 영문, 숫자, _로 2~20글자 입력해야 하며, 특수문자를 입력할 수 없습니다.",
            color:'warning'})
        }
        if(!accountIdCheck) {

          this.$vs.notify({
            title:'아이디',
            text:"아이디는 6~12 글자, 공백 및 특수문자를 포함할 수 없습니다.",
            color:'warning'})
        }
        if(!passwordCheck) {

          this.$vs.notify({
            title:'비밀번호',
            text:"비밀번호는 8~15개의 영문 대소문자, 1개 이상의 특수문자를 반드시 포함해야 합니다.",
            color:'warning'})
        }
        if(!hpCheck) {

          this.$vs.notify({
            title:'전화번호',
            text:"올바른 형식의 전화번호를 입력해주세요.",
            color:'warning'})
        }

        return nameCheck && accountIdCheck && passwordCheck && hpCheck
      }
      else {
        
        return false
      }
    },

    getUserList() {
      const thisIns = this;

      this.$http
        .post("/api/adComp/list", {})
        .then(function(response) {

          console.log('response.data:', response.data)
        })
        .catch(function(error) {
          var errorText = error.toString();
          if(errorText.includes('401')){
            alert('로그인 해주세요!')
            thisIns.$router.push({path: '/pages/login?to='+ thisIns.$router.history.current.path, query: thisIns.$router.history.current.query})
          }
          thisIns.$vs.notify({
            title: "Error",
            text: error,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle"
          });
        });
    },

    buildSearchParam() {

      var param = {};

      return param
    },

    resetPagePosition() {
      this.currentx = 1;
      this.renderMethod.startRow = 0;
    },

    updateSearchText() {
      this.searchText = this.renderMethod.inputText
    },
    showSidebar() {
        this.$store.commit('TOGGLE_IS_SIDEBAR_ACTIVE', true);
    },
  },
  created() {
    const thisIns = this;

    this.$http
      .post("/api/adComp/list", thisIns.renderMethod)
      .then(function(response) {
        // thisIns.numOfUsers = response.data[0][0].user_count;
        // thisIns.numOfPages = parseInt(
        //   response.data[0][0].user_count / thisIns.renderMethod.itemsPerPage
        // );
        // thisIns.users = response.data[1];

        thisIns.users = response.data

        console.log('response.data:', response.data)
      })
      .catch(function(error) {
        var errorText = error.toString();
        if(errorText.includes('401')){
          alert('로그인 해주세요!')
          console.log(thisIns.$router)
          thisIns.$router.push({path: '/pages/login?to='+ thisIns.$router.history.current.path, query: thisIns.$router.history.current.query})
        }
        thisIns.$vs.notify({
          title: "Error",
          text: error,
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-circle"
        });
      });
  },
  mounted() {
    this.isMounted = true;
  }
};
</script>

<style lang="scss">
@import "@/assets/scss/custom-style/vs-table-reporter.scss";
</style>